import {
  UserExecutionStatusIdMap,
  UserExecutionStatusName,
} from "@/types/callbotRecord";
import { camelToSnakeCase } from "@/helpers";
import { type Option } from "react-tailwindcss-select/dist/components/type";
import { type Row } from "@tanstack/table-core/src/types";
import { type Task } from "@/types/task";

export const getStatusOptions = (): Option[] => {
  const options: Option[] = [];
  options.push({
    value: camelToSnakeCase(UserExecutionStatusName.REVIEW_COMPLETED) as string,
    label: UserExecutionStatusName.REVIEW_COMPLETED
  });
  options.push({
    value: camelToSnakeCase(UserExecutionStatusIdMap["20"]) as string,
    label: UserExecutionStatusIdMap["20"],
  });
  options.push({
    value: camelToSnakeCase(UserExecutionStatusIdMap["4"]) as string,
    label: UserExecutionStatusIdMap["4"],
  });
  return options;
};

export const getActivityOptions = () => {
  return [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
  ];
};
